<template>
  <b-dropdown variant="primary" text="Выбрать товары" ref="dropdown">
    <li class="catalog-menu overflow-hidden" v-if="catalog && catalog.length">
      <div
        v-if="showHeader"
        class="catalog-menu__header"
      >
        <div
          @click.stop="closeSubmenu()"
          class="catalog-menu__back"
        >
          <SpriteIcon icon="check" /> Назад
        </div>
        <div
          v-if="route && route.length"
          class="catalog-menu__breadcrumbs"
        >
          <div class="breadcrumbs__item">
            <span @click.stop="closeSubmenu(0)">{{ sectionName }} </span>
          </div>
          <div
            v-for="(item, key) in route"
            :key="key"
            class="breadcrumbs__item"
          >
            &nbsp;-&nbsp;<span @click.stop="closeSubmenu(key + 1)">{{ item.title }}</span>
          </div>
        </div>
      </div>
      <perfect-scrollbar
        :options="{ wheelPropagation: false }"
      >
        <CatalogMenu
          :catalog="catalog"
          :activeSections="activeSections"
          :activeProducts="activeProducts"
          :depth="0"
          topMenu
          :route="route"

          @submenuOpened="openSubmenu($event)"
          @sectionAdded="$emit('sectionAdded', $event.sectionId)"
          @sectionRemoved="$emit('sectionRemoved', $event.sectionId)"
          @productAdded="$emit('productAdded', $event.productId)"
          @productRemoved="$emit('productRemoved', $event.productId)"
        />
      </perfect-scrollbar>
    </li>
    <b-dropdown-item
      v-else
      disabled
    >
      Элементы каталога не найдены
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import CatalogMenu from '@/components/sale/CatalogDropdown/CatalogMenu'

export default {
  name: 'CatalogDropdown',
  components: {
    CatalogMenu
  },
  props: {
    catalog: {
      type: Array
    },
    activeSections: {
      type: Array
    },
    activeProducts: {
      type: Array
    }
  },
  computed: {
    showHeader () {
      return this.route && this.route.length > 0
    }
  },
  data () {
    return {
      sectionName: 'Каталог',
      route: []
    }
  },
  methods: {
    openSubmenu (item) {
      this.route.push(item)
    },
    closeSubmenu (index = null) {
      if (this.route && this.route.length > 0) {
        let start = this.route.length - 1
        let count = 1

        if (index !== null) {
          start = index
          count = this.route.length - index
        }

        this.route.splice(start, count)
      }
    }
  }
}
</script>

<style scoped>
.catalog-menu {
  white-space: normal;
}
.catalog-menu__header {
  margin: 8px 20px 5px;
  padding: 0 0 15px 0;
  border-bottom: 1px solid var(--border-color);
  font-size: 12px;
}
.catalog-menu__back {
  display: inline-block;
  cursor: pointer;
  color: var(--title-color);
  font-weight: 600;
  margin-bottom: 5px;
}
.catalog-menu__back .sprite_icon.check {
  transform: rotate(90deg);
}
.catalog-menu__back:hover {
  color: var(--text-color);
}
.catalog-menu__breadcrumbs {
  color: var(--text-color);
  font-weight: normal;
  display: flex;
  align-items: center;
}
.breadcrumbs__item {
  white-space: nowrap;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.breadcrumbs__item:not(:last-child) span {
  cursor: pointer;
}
.breadcrumbs__item:not(:last-child):hover span {
  color: var(--title-color);
}
.catalog-menu .ps {
  height: 300px;
}
</style>
