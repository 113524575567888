<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else>
    <div
      class="block-head mt-30"
      :class="{
        'mb-25': isPlanCopied,
        'mb-30': !isPlanCopied
      }"
    >
      <h1
        :class="{
          'mb-20': isPlanCopied,
          'mb-10': !isPlanCopied
        }"
      >
        План компании: <span class="currency">{{ totalPlan | money }}</span>
      </h1>
      <p v-if="isEmptyPlans">Для начала работы выберите сотрудников вашей компании, которые участвуют в продажах, и назначьте им план.<br>Чтобы сотрудник участвовал в плане продаж своего отдела, ему необходимо подтвердить назначенный план.</p>
      <AlertBlock v-if="isPlanCopied" class="mb-0" icon="warning" grey close>Сотрудники плана компании скопированы с предыдущего месяца</AlertBlock>
    </div>
    <b-table-simple class="table_plan table_plan_edit mb-0">
      <b-thead>
        <b-tr>
          <b-th width="19%">Сотрудник</b-th>
          <b-th width="15%"><span>План</span><SpriteIcon icon="question" class="ml-10 sm" tooltipText="Выполнение менеджерами данного показателя позволит компании активно расти и развиваться" /></b-th>
          <b-th v-if="min_sum_mode" width="20%"><span>Нижний порог</span><SpriteIcon icon="question" class="ml-10 sm" tooltipText="Выполнение менеджерами указанного показателя позволит компании покрыть все расходы и не попасть в кассовый разрыв" /></b-th>
          <b-th v-if="showWishSumColumn" width="25%">Предложено сотрудником</b-th>
          <b-th width="12%"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>
            <EntitySelect2
              url="user"
              v-model="newPlan.user_id"
              label="name"
              placeholder="Выбрать сотрудника"
              :state="valid === false && errors.user !== null ? false : null"
            />
          </b-th>
          <b-th>
            <CurrencyInput
              v-model="newPlan.sum"
              placeholder="Введите сумму"
              :state="valid === false && errors.sum !== null ? false : null"
              @blur="validateSumField()"
            />
          </b-th>
          <b-th v-if="min_sum_mode">
            <CurrencyInput
              v-model="newPlan.min_sum"
              placeholder="Введите сумму"
              :state="valid === false && errors.minSum !== null ? false : null"
              @blur="validateMinSumField()"
            />
          </b-th>
          <b-th v-if="showWishSumColumn"></b-th>
          <b-th>
            <div class="text-right font-weight-normal">
              <a href="#" class="dotted-blue" @click.prevent="addPlan">Добавить сотрудника</a>
            </div>
          </b-th>
        </b-tr>
        <b-tr
          v-for="item in plans"
          :key="item.id"
        >
          <b-td>
            <Avatar :photo="users[item.user_id].avatar" :title="users[item.user_id].name" />
          </b-td>
          <b-td>
            <CurrencyInput
              v-model="item.sum"
              @blur="updatePlan(item)"
              placeholder="Введите сумму"
            />
          </b-td>
          <b-td v-if="min_sum_mode">
            <CurrencyInput
              v-model="item.min_sum"
              @blur="updatePlan(item)"
              placeholder="Введите сумму"
            />
          </b-td>
          <b-td v-if="showWishSumColumn">
            <template v-if="item.wish_sum > 0">
              <span class="currency">{{ item.wish_sum | money }}</span><span v-if="item.wish_sum_updated_at" class="rx-text font-weight-normal ml-10">(был изменен {{ formatDate(item.wish_sum_updated_at) }})</span>
            </template>
          </b-td>
          <b-td>
            <div class="d-flex justify-content-end">
              <div @click.prevent="removePlan(item.id)">
                <Remove tooltipText="Удалить сотрудника" />
              </div>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import Remove from '@/components/ui/icons/TrashIcon'
import moment from 'moment'
import NotifyMixin from '@/mixins/NotifyMixin.js'
import { mapGetters, mapState } from 'vuex'
import CurrencyInput from '@/components/ui/CurrencyInput'
import AlertBlock from '@/components/ui/AlertBlock'
import Avatar from '@/components/ui/Avatar'

export default {
  name: 'TableProgessEdit',
  components: {
    Remove,
    CurrencyInput,
    AlertBlock,
    Avatar
  },
  mixins: [NotifyMixin],
  props: {
    plans: {
      type: Array,
      default: () => []
    },
    users: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    isPlanCopied: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newPlan: {
        user_id: null,
        min_sum: null,
        sum: null,
        status: 'confirm',
        wish_sum: 0
      },
      errors: {
        user: null,
        minSum: null,
        sum: null
      },
      valid: true
    }
  },
  computed: {
    ...mapGetters('auth', ['current']),
    ...mapGetters('sale/date', ['dateQuery', 'isPreviousDate']),
    ...mapState('sale/settings', ['planning_mode', 'min_sum_mode', 'copy_plan']),
    totalPlan () {
      return this.plans ? this.plans.reduce((sum, current) => {
        return parseInt(sum) + (parseInt(current.sum) ? parseInt(current.sum) : 0)
      }, 0) : 0
    },
    showWishSumColumn () {
      let result = false

      if (this.plans && this.plans.length > 0) {
        const sendedPlans = this.plans.filter(el => el.status === 'sended')

        if (sendedPlans && sendedPlans.length > 0) {
          result = true
        }
      }

      return result
    },
    isEmptyPlans () {
      return this.plans && !this.plans.length
    }
  },
  async created () {
    await this.$store.dispatch('sale/settings/fetchSettings')
    this.fetchPlanData()
  },
  methods: {
    fetchPlanData () {
      this.$emit('data-update')
    },
    async addPlan () {
      this.errors = {}
      this.valid = true

      const validUser = this.validateUser(this.newPlan.user_id)
      this.errors.user = validUser.error
      if (this.valid) {
        this.valid = validUser.valid
      }

      const validMinSum = this.validateMinSum(this.newPlan.min_sum)
      this.errors.minSum = validMinSum.error
      if (this.valid) {
        this.valid = validMinSum.valid
      }

      const validSum = this.validateSum(this.newPlan.min_sum, this.newPlan.sum)
      this.errors.sum = validSum.error
      if (this.valid) {
        this.valid = validSum.valid
      }

      if (this.valid === false) {
        for (const key in this.errors) {
          if (Object.hasOwnProperty.call(this.errors, key)) {
            const message = this.errors[key]
            this.notifyError('Добавление сотрудника', message)
          }
        }
      } else {
        try {
          // if set plan for yourself OR selected previous period then accept it automatically
          if ((this.current.id === this.newPlan.user_id && !this.planning_mode) || this.isPreviousDate) {
            this.newPlan.status = 'accepted'
          }

          await this.$api.post(`sale/user-plan${this.dateQuery}`, this.newPlan)

          this.newPlan = {
            user_id: null,
            min_sum: null,
            sum: null
          }

          this.fetchPlanData()
        } catch (e) {
          this.error = 'Не удалось сохранить план'
        }
      }
    },
    async removePlan (planId) {
      try {
        await this.$api.delete(`sale/user-plan/${planId}`)
        const planIndex = this.plans.findIndex(el => el.id === planId)
        this.plans.splice(planIndex, 1)
      } catch (e) {
        this.error = 'Не удалось удалить план'
      }
    },
    async updatePlan (plan) {
      let status = plan.status !== 'empty' ? plan.status : 'confirm'
      // if set plan for yourself OR selected previous period then accept it automatically
      if ((this.current.id === plan.user_id && !this.planning_mode) || this.isPreviousDate) {
        status = 'accepted'
      }

      try {
        await this.$api.put(`sale/user-plan/${plan.id}`, {
          sum: parseInt(plan.sum, 10),
          min_sum: parseInt(plan.min_sum, 10),
          status: status
        })
      } catch (e) {
        this.error = 'Не удалось обновить план'
      }
    },
    formatDate (date) {
      return moment.unix(date).format('DD.MM.YYYY в HH:mm')
    },
    validateUser (userId) {
      if (!userId) {
        return { valid: false, error: 'Поле "Сотрудник" обязательно для заполнения' }
      }
      if (this.plans && this.plans.find(el => el.user_id === userId) !== undefined) {
        return { valid: false, error: 'Сотрудник уже добавлен в план' }
      }
      return { valid: true, error: null }
    },
    validateMinSum (sum) {
      if (this.min_sum_mode) {
        sum = parseInt(sum)
        if (!sum && sum !== 0) {
          return { valid: false, error: 'Поле "Нижний порог" обязательно для заполнения' }
        }
        if (sum === 0) {
          return { valid: false, error: 'Нижний порог должен быть больше 0' }
        }
      }
      return { valid: true, error: null }
    },
    validateSum (minSum, sum) {
      if (!sum) {
        return { valid: false, error: 'Поле "План" обязательно для заполнения' }
      }
      sum = parseInt(sum)
      if (sum <= 0) {
        return { valid: false, error: 'План должен быть больше 0' }
      }
      minSum = parseInt(minSum)
      if (minSum && sum <= minSum) {
        return { valid: false, error: 'План должен быть больше нижнего порога' }
      }
      return { valid: true, error: null }
    },
    validateSumField () {
      const validSum = this.validateSum(this.newPlan.min_sum, this.newPlan.sum)

      if (validSum.valid === false) {
        this.valid = validSum.valid
        this.errors.sum = validSum.error
        this.notifyError('Добавление сотрудника', validSum.error)
      } else {
        this.errors.sum = null
      }
    },
    validateMinSumField () {
      const validMinSum = this.validateMinSum(this.newPlan.min_sum)

      if (validMinSum.valid === false) {
        this.valid = validMinSum.valid
        this.errors.minSum = validMinSum.error
        this.notifyError('Добавление сотрудника', validMinSum.error)
      } else {
        this.errors.minSum = null
      }

      this.validateSumField()
    }
  }
}
</script>

<style scoped>
.form-control {
  max-width: 190px;
}
</style>
