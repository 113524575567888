<template>
  <div>
    <TopPanel
      actions
      pageSwitcher
      @refresh-click="fetchData()"
    />

    <div v-if="isLoading"><Loader /></div>
    <div v-else>
      <WarningDeals
        @plan-update="fetchData()"
      />
      <Block>
        <div class="block-head border-bottom pb-10">
          <div class="d-flex justify-content-between align-items-center">
            <div class="h1">План компании</div>
            <DateSelect @date-update="fetchData()" />
          </div>
        </div>
        <PlanChart
          :plan="plan"
          :minPlan="minPlan"
          :fact="fact"
          :predict="predict"
        />
      </Block>
      <Block>
        <div class="block-head pb-20" v-if="activeTabData">
          <div class="d-flex align-items-center">
            <div class="h1 mb-0" v-if="activeTabData.title">{{ activeTabData.title }}</div>
            <router-link
              v-if="activeTabData.link"
              class="dotted-blue ml-20"
              :to="activeTabData.link"
            >
              Редактировать
            </router-link>
          </div>
        </div>
        <b-tabs v-model="activeTabIndex">
          <b-tab title="Сотрудники">
            <TableProgress @plan-update="fetchData()" />
          </b-tab>
          <b-tab title="Отделы">
            <TableGroupProgress />
          </b-tab>
          <b-tab title="Направления">
            <template v-if="directions && directions.length > 0">
              <TableDirectionProgress
                :directionsData="directions"
                :totalFact="fact"
              />
            </template>
            <Alert
              v-else
              icon="warning"
              class="mt-30"
            >
              Направления еще созданы.
              <router-link
                class="dotted-blue"
                to="/sale/plan/progress/edit#direction"
              >
                Перейти к добавлению
              </router-link>
            </Alert>
          </b-tab>
        </b-tabs>
      </Block>
    </div>
  </div>
</template>

<script>
import PlanChart from '@/components/sale/Chart'
import TableProgress from '@/components/sale/TableProgress'
import TableGroupProgress from '@/components/sale/TableGroupProgress'
import TableDirectionProgress from '@/components/sale/TableDirectionProgress'
import WarningDeals from '@/components/sale/WarningDeals.vue'
import TopPanel from '@/components/sale/TopPanel'
import CanMixin from '@/mixins/CanMixin'
import DateSelect from '@/components/sale/DateSelect'
import { mapGetters } from 'vuex'
import Alert from '@/components/ui/Alert'

export default {
  name: 'Progress',
  components: {
    PlanChart,
    TableProgress,
    TableGroupProgress,
    TableDirectionProgress,
    TopPanel,
    WarningDeals,
    DateSelect,
    Alert
  },
  mixins: [CanMixin],
  computed: {
    ...mapGetters('auth', ['current']),
    ...mapGetters('sale/date', ['dateQuery']),
    activeTabData () {
      return this.tabsData[this.activeTabIndex]
    }
  },
  data () {
    return {
      isLoading: true,
      error: '',
      plan: 0,
      minPlan: 0,
      fact: 0,
      predict: 0,
      directions: [],
      activeTabIndex: null,
      tabsData: [
        {
          title: 'Прогресс сотрудников',
          link: '/sale/plan/progress/edit'
        },
        {
          title: 'Прогресс отделов'
        },
        {
          title: 'Прогресс направлений',
          link: '/sale/plan/progress/edit#direction'
        }
      ]
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      await this.fetchPlanData()
      await this.fetchDirectionsData()
    },
    async fetchPlanData () {
      this.isLoading = true

      try {
        const result = await this.$api.query(`sale/user-plan${this.dateQuery}`)
        this.plan = result.sum
        this.minPlan = result.min_sum
        this.fact = result.fact_sum
        this.predict = result.predict_sum
      } catch (e) {
        this.error = 'Не удалось получить данные'
      }

      this.isLoading = false
    },
    async fetchDirectionsData () {
      try {
        this.directions = await this.$api.query(`sale/user-plan/direction${this.dateQuery}`)
      } catch (e) {
        this.error = 'Не удалось получить данные направлений'
      }
    }
  }
}
</script>
