<template>
  <div>
    <TopPanel
      :back="!isGoBackLinkHidden"
      backText="Вернуться назад"
      @back-click="$router.go(-1)"
    >
      <div slot="end">
        <DateSelect @date-update="fetchData()" />
      </div>
    </TopPanel>
    <Block>
      <div class="tabs">
        <b-nav tabs>
          <b-nav-item to="#" :active="$route.hash === '#' || $route.hash === ''">
            План продаж
          </b-nav-item>
          <b-nav-item to="#direction" :active="$route.hash === '#direction'">
            План направлений
          </b-nav-item>
        </b-nav>
        <div class="tab-content">
          <div :class="['tab-pane', { 'active': $route.hash === '#' || $route.hash === '' }]">
            <TableProgessEdit
              :plans="plans"
              :users="users"
              :isLoading="isLoadingPlans"
              :isPlanCopied="isPlanCopied"
              @data-update="fetchPlanData()"
            />
          </div>
          <div :class="['tab-pane', { 'active': $route.hash === '#direction' }]">
            <TableDirectionsEdit
              :directions="directions"
              :catalog="catalog"
              :pipelines="pipelines"
              :isLoading="isLoadingDirections"
              @data-update="fetchDirectionData()"
            />
          </div>
        </div>
      </div>
    </Block>
    <div
      v-if="!isLoadingPlans && !isLoadingDirections && isGoBackLinkHidden"
      class="d-flex align-items-center"
    >
      <router-link
        to="/sale/plan/progress"
        class="mr-15"
        :disabled="isPlanLinkDisabled"
        :event="isPlanLinkDisabled ? '' : 'click'"
      >
        <span
          class="btn btn-primary"
          :class="{ disabled: isPlanLinkDisabled }"
        >
          Перейти на план
        </span>
      </router-link>
      <Alert v-if="isPlanLinkDisabled" icon="warning">Чтобы перейти к плану продаж, добавьте хотя бы одного сотрудника и назначьте ему план</Alert>
    </div>
  </div>
</template>

<script>
import TopPanel from '@/components/TopPanel'
import TableProgessEdit from '@/components/sale/TableProgressEdit'
import TableDirectionsEdit from '@/components/sale/TableDirectionsEdit'
import Alert from '@/components/ui/Alert'
import DateSelect from '@/components/sale/DateSelect'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    TopPanel,
    TableProgessEdit,
    TableDirectionsEdit,
    DateSelect,
    Alert
  },
  name: 'ProgressEdit',
  data () {
    return {
      isLoadingPlans: true,
      isLoadingDirections: true,
      plans: [],
      users: [],
      isPlanCopied: false,
      directions: [],
      catalog: [],
      pipelines: []
    }
  },
  computed: {
    ...mapGetters('sale/date', ['isPresentMonth', 'dateQuery']),
    ...mapState('sale/settings', ['planning_mode', 'min_sum_mode', 'copy_plan']),
    isGoBackLinkHidden () {
      return this.plans === undefined || (this.plans && this.plans.length === 0)
    },
    isPlanLinkDisabled () {
      return this.plans === undefined || (this.plans && this.plans.length === 0)
    }
  },
  methods: {
    async fetchData () {
      await this.fetchPlanData()
      await this.fetchDirectionData()
    },
    async fetchPlanData () {
      this.isLoadingPlans = true
      this.isPlanCopied = false

      try {
        let result = await this.$api.query(`sale/user-plan${this.dateQuery}`)

        if (this.isPresentMonth && result.plans === undefined && this.copy_plan) {
          const copyResult = await this.copyPlanDataFromPreviousMonth()

          if (copyResult === true) {
            this.isPlanCopied = true
            result = await this.$api.query(`sale/user-plan${this.dateQuery}`)
          }
        }

        await this.fetchUserData()
        this.plans = result.plans
      } catch (e) {
        this.error = 'Не удалось получить данные'
      }

      this.isLoadingPlans = false
    },
    async fetchUserData () {
      try {
        const result = await this.$api.query(`sale/user${this.dateQuery}`)
        if (result) {
          result.forEach(el => {
            this.users[el.id] = el
          })
        }
      } catch (e) {
        this.error = 'Не удалось получить данные пользователей'
      }
    },
    async copyPlanDataFromPreviousMonth () {
      try {
        const result = await this.$api.query('sale/user-plan/copy')

        return result
      } catch (e) {
        this.error = 'Не скопировать данные с предыдущего месяца'
      }
    },
    async fetchDirectionData () {
      this.isLoadingDirections = true

      try {
        const result = await this.$api.query(`sale/direction${this.dateQuery}`)

        await this.fetchPipelineData()
        await this.fetchCatalogData()

        this.directions = result
      } catch (e) {
        this.error = 'Не удалось получить данные'
      }

      this.isLoadingDirections = false
    },
    async fetchPipelineData () {
      try {
        this.pipelines = await this.$api.query('sale/pipeline')
      } catch (e) {
        console.error('Не удалось загрузить направления сделок')
      }
    },
    async fetchCatalogData () {
      try {
        this.catalog = await this.$api.query('sale/catalog')
      } catch (e) {
        console.error('Не удалось загрузить каталог')
      }
    }
  }
}
</script>
