<template>
  <ul
    class="catalog-menu__wrapper"
    :class="{'catalog-menu__top': topMenu !== false}"
    :style="indent"
  >
    <li
      class="list__item"
      :class="{ opened: route[depth] && route[depth].index === index, 'item-product': !isSection(item) }"
      v-for="(item, index) in catalog"
      :key="index"
    >
      <div class="list__item-wrapper">
        <div class="item__switch">
          <Switchbox
            class="item__checkbox"
            small
            :value="isActiveSection(item) || isActiveProduct(item) ? true : false"
            @input="switchboxChanged({ item: item, value: $event })"
          />
        </div>
        <div
          class="item__info"
          @click="isSection(item) ? emitSubmenuOpened({index: index, title: item.title}) : undefined"
        >
          <div class="item__title">{{ item.title }} <span v-if="isActiveSection(item) && isSection(item)" class="rx-text">({{ countActiveMenuElements(item) }} из {{ countMenuElements(item) }})</span></div>
          <div v-if="isSection(item)" class="item__arrow"><SpriteIcon icon="check" /></div>
        </div>
      </div>
      <div
        v-if="isSection(item)"
        class="catalog-submenu"
      >
        <CatalogMenu
          :catalog="item.children ? item.children : []"
          :activeSections="activeSections"
          :activeProducts="activeProducts"
          :depth="depth + 1"
          :route="route"

          @submenuOpened="emitSubmenuOpened($event)"
          @sectionAdded="sectionAddedHandler($event)"
          @sectionRemoved="sectionRemovedHandler($event)"
          @productAdded="productAddedHandler($event)"
          @productRemoved="productRemovedHandler($event)"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import Switchbox from '@/components/ui/Switchbox'

export default {
  name: 'CatalogMenu',
  components: {
    Switchbox
  },
  props: {
    catalog: {
      type: Array
    },
    topMenu: {
      type: Boolean,
      default: false
    },
    depth: {
      type: Number
    },
    route: {
      type: Array
    },
    activeSections: {
      type: Array
    },
    activeProducts: {
      type: Array
    }
  },
  computed: {
    indent () {
      const indentValue = this.route[this.depth] !== undefined ? -100 : 0
      return `transform: translateX(${indentValue}%)`
    }
  },
  methods: {
    isSection (item) {
      return item.type === 'section'
    },
    isProduct (item) {
      return item.type === 'product'
    },
    emitSubmenuOpened (value) {
      this.$emit('submenuOpened', {
        index: value.index,
        title: value.title
      })
    },
    isActiveSection (item) {
      return this.isSection(item) && this.activeSections.indexOf(parseInt(item.id)) !== -1
    },
    isActiveProduct (item) {
      return this.isProduct(item) && this.activeProducts.indexOf(parseInt(item.id)) !== -1
    },
    switchboxChanged (value) {
      if (this.isSection(value.item)) {
        if (value.value) {
          this.selectSection(value.item.id, value.item.parent_id, value.item.children)
        } else {
          this.unselectSection(value.item.id, value.item.parent_id, value.item.children)
        }
      } else if (this.isProduct(value.item)) {
        if (value.value) {
          this.selectProduct(value.item.id, value.item.section_id)
        } else {
          this.unselectProduct(value.item.id, value.item.section_id)
        }
      }
    },
    countMenuElements (catalog) {
      return catalog.children ? catalog.children.length : 0
    },
    countActiveMenuElements (catalog) {
      let total = 0

      this.activeSections.forEach(el => {
        total += this.childrenSectionsId(catalog.children).indexOf(parseInt(el)) !== -1 ? 1 : 0
      })

      this.activeProducts.forEach(el => {
        total += this.childrenProductsId(catalog.children).indexOf(parseInt(el)) !== -1 ? 1 : 0
      })

      return total
    },
    childrenSectionsId (catalog) {
      return catalog
        .filter(item => this.isSection(item))
        .map(item => {
          return parseInt(item.id)
        })
    },
    childrenProductsId (catalog) {
      return catalog
        .filter(item => this.isProduct(item))
        .map(item => {
          return parseInt(item.id)
        })
    },
    selectChildren (children) {
      children.forEach(item => {
        if (this.isSection(item)) {
          this.selectSection(item.id, item.parent_id, item.children)
        } else if (this.isProduct(item)) {
          this.selectProduct(item.id, item.section_id)
        }
      })
    },
    unselectChildren (children) {
      children.forEach(item => {
        if (this.isSection(item)) {
          this.unselectSection(item.id, item.parent_id, item.children)
        } else if (this.isProduct(item)) {
          this.unselectProduct(item.id, item.section_id)
        }
      })
    },
    selectProduct (productId, sectionId) {
      this.$emit('productAdded', { productId: productId, sectionId: sectionId })
    },
    unselectProduct (productId, sectionId) {
      this.$emit('productRemoved', { productId: productId, sectionId: sectionId })
    },
    selectSection (sectionId, parentId, children) {
      this.$emit('sectionAdded', { sectionId: sectionId, parentId: parentId })

      if (children) {
        this.selectChildren(children)
      }
    },
    unselectSection (sectionId, parentId, children) {
      this.$emit('sectionRemoved', { sectionId: sectionId, parentId: parentId })

      if (children) {
        this.unselectChildren(children)
      }
    },
    sectionAddedHandler (value) {
      const parentSection = this.catalog.find(el => el.type === 'section' && el.id === value.parentId)
      if (parentSection !== undefined) {
        if (this.activeSections.indexOf(parentSection.id) === -1) { // if section is active and parent section is not active, then activate parent section
          this.selectSection(parentSection.id, parentSection.parent_id, null)
        }
      }

      this.$emit('sectionAdded', value)
    },
    sectionRemovedHandler (value) {
      const parentSection = this.catalog.find(el => el.type === 'section' && el.id === value.parentId)
      if (parentSection !== undefined) {
        const activeProducts = parentSection.children.filter(el => el.type === 'product' && this.activeProducts.indexOf(parseInt(el.id)) !== -1)
        const activeSections = parentSection.children.filter(el => el.type === 'section' && el.id !== value.sectionId && this.activeSections.indexOf(parseInt(el.id)) !== -1)

        if (activeSections.length === 0 && activeProducts.length === 0) { // if deactivated all children sections, then deactivate parent section
          this.unselectSection(parentSection.id, parentSection.parent_id, null)
        }
      }

      this.$emit('sectionRemoved', value)
    },
    productAddedHandler (value) {
      const productSection = this.catalog.find(el => el.type === 'section' && el.id === value.sectionId)
      if (productSection !== undefined) {
        const activeSections = productSection.children.filter(el => el.type === 'section' && this.activeSections.indexOf(parseInt(el.id)) !== -1)

        if (activeSections.indexOf(productSection.id) === -1) { // if product is active in not active section, then activate section
          this.selectSection(productSection.id, productSection.parent_id, null)
        }
      }
      this.$emit('productAdded', value)
    },
    productRemovedHandler (value) {
      const productSection = this.catalog.find(el => el.type === 'section' && el.id === value.sectionId)
      if (productSection !== undefined) {
        const activeProducts = productSection.children.filter(el => el.type === 'product' && el.id !== value.productId && this.activeProducts.indexOf(parseInt(el.id)) !== -1)
        const activeSections = productSection.children.filter(el => el.type === 'section' && this.activeSections.indexOf(parseInt(el.id)) !== -1)

        if (activeProducts.length === 0 && activeSections.length === 0) { // if deactivated all children sections and products, then deactivate section
          this.unselectSection(productSection.id, productSection.parent_id, productSection.children)
        }
      }
      this.$emit('productRemoved', value)
    }
  }
}
</script>

<style scoped>
.catalog-menu__wrapper {
  list-style: none;
  padding: 10px 20px;
  transition: transform .2s ease-in-out;
}
.list__item {
  margin-bottom: 15px;
}
.list__item-wrapper {
  display: flex;
  align-items: flex-start;
}
.list__item:last-child {
  margin-bottom: 0;
}
.item__switch {
  display: flex;
  align-items: center;
}
.item__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 10px;
  font-size: 12px;
}
.list__item:not(.item-product) .list__item-wrapper > .item__info {
  cursor: pointer;
}
.item__title {
  font-size: 12px;
  color: var(--title-color);
}
.list__item:not(.item-product):not(.opened):hover .list__item-wrapper > .item__info .item__title {
  color: var(--text-color);
}
.item__arrow .sprite_icon.check {
  transform: rotate(-90deg);
  color: var(--text-color);
}
.list__item:hover .item__info .sprite_icon.check {
  color: var(--title-color);
}
.catalog-submenu {
  left: 100%;
  top: 0;
  width: 100%;
  display: none;
  position: absolute;
  z-index: 5;
  margin: 0;
  padding: 0;
}
.list__item.opened > .catalog-submenu {
  display: block;
}
</style>
