<template>
  <div>
    <div
      :id="`trash_${id}`"
      class="trash"
      @click="$emit('remove-click')"
    >
      <svg width="12" height="13"><use :xlink:href="trashSvg + '#icon'"></use></svg>
    </div>
    <b-tooltip
      v-if="tooltipText.length > 0"
      :target="`trash_${id}`"
      :title="tooltipText"
    ></b-tooltip>
  </div>
</template>

<script>
import trashSvg from '@/assets/svg/trash_icon.svg'

export default {
  name: 'TrashIcon',
  props: {
    tooltipText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      trashSvg,
      id: this._uid
    }
  }
}
</script>

<style scoped>
.trash {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: var(--text-color);
  border-radius: 7px;
  cursor: pointer;
  border: 1px solid var(--border-color);
}
.trash:hover svg, a:hover .trash svg {
  color: var(--title-color);
}
</style>
