<template>
  <div class="switch" :class="{ 'switch-on': val, 'switch-off': !val, 'switch-disabled': disabled, small }" @click="toggle">
    <div class="switch-circle"></div>
  </div>
</template>

<script>
export default {
  name: 'Switchbox',
  props: {
    value: Boolean,
    disabled: Boolean,
    small: Boolean
  },
  data () {
    return {
      val: this.value
    }
  },
  watch: {
    value () {
      this.val = this.value
    }
  },
  methods: {
    toggle () {
      if (this.disabled) {
        return
      }
      this.val = !this.val
      this.$emit('input', this.val)
      this.$emit('change', this.val)
    }
  }
}
</script>

<style>
.switch {
  display: inline-block;
  position: relative;
  width: 34px;
  height: 20px;
  border-radius: 100px;
  transition: background-color .3s;
  cursor: pointer;
}
.switch.small {
  width: 28px;
  height: 16px;
}
.switch-disabled {
  opacity: .7;
  cursor: not-allowed;
}
.switch-circle {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 3px;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(128, 139, 172, 0.5);
  border-radius: 50%;
  transition: left .3s;
}
.switch.small .switch-circle {
  width: 12px;
  height: 12px;
  top: 2px;
}
.switch.switch-on {
  background-color: var(--title-color);
}
.switch.switch-off {
  background-color: var(--border-color);
}
.switch.switch-on .switch-circle {
  left: 17px;
}
.switch.switch-off .switch-circle {
  left: 3px;
}
.switch.switch-on.small .switch-circle {
  left: 14px;
}
.switch.switch-off.small .switch-circle {
  left: 2px;
}
</style>
