<template>
  <div class="rank-label">
    <div
      v-if="place >= 1 && place <= 3 && !empty"
      class="rank-label-img"
    >
      <img
        :src="require(`@/assets/svg/plan/${place}_place.svg`)"
        :alt="place">
    </div>
    <div
      v-else
      class="rank-label-number"
    >
        <span v-if="!empty">{{ place }}</span>
        <span v-else-if="question">?</span>
        <span v-else>-</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RankLabel',
  props: {
    place: Number,
    empty: Boolean,
    question: Boolean
  }
}
</script>

<style>
.rank-label .rank-label-number {
  width: 36px;
  height: 36px;
  border-radius: 30px;
  text-align: center;
  border: 1px solid var(--border-color);
  padding: 9px 8px;
  color: var(--text-color);
  font-weight: normal;
}
.rank-label .rank-label-number, .rank-label-img {
  margin-right: 15px;
}
</style>
