<template>
  <div class="direction-progress mt-30">
    <Bar
      :bars="directionsBar"
      :max="totalFact"
    />
    <div class="table-responsive mb-0 mt-30">
      <b-table-simple
        class="table_plan mb-0"
      >
        <b-thead>
          <b-tr>
            <b-th width="21%">Направление</b-th>
            <b-th width="36%">Прогресс</b-th>
            <b-th width="12.5%">Необходимо</b-th>
            <b-th width="16%">Выполнено</b-th>
            <b-th>Осталось</b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr
            v-for="item in directions"
            :key="item.id"
          >
            <b-td>
              <span
                class="dot-icon mr-10"
                :style="`background: ${item.background}`"
              ></span>{{ item.title }}
            </b-td>
            <b-td>
              <Bar
                v-if="item.plan"
                style="max-width: 200px"
                :bars="[
                  { start: 0, stop: item.fact + item.predict, background: '#B9C3D8', title: 'Выставлено счетов на' },
                  { start: 0, stop: item.fact, background: 'multicolor', title: 'План выполнен на' }
                ]"
                :max="item.plan"
                :pointerCursor="isPresentMonth"
                :pointerOffset="!isPresentMonth ? 100 : undefined"
                small
              >
                <template slot="label-after">
                  <div class="bar-progress__label">
                    {{ item.factPercent }}%
                  </div>
                </template>
              </Bar>
            </b-td>
            <b-td>
              <span v-if="item.plan" class="currency">{{ item.plan | money }}</span>
            </b-td>
            <b-td>
              <span class="currency">{{ item.fact | money }}</span>
            </b-td>
            <b-td>
              <span v-if="item.plan" class="currency">{{ restSum(item.plan, item.fact) | money }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import Bar from '@/components/sale/Bar'
import { mapGetters } from 'vuex'

export default {
  name: 'TableDirectionProgress',
  components: {
    Bar
  },
  props: {
    directionsData: {
      type: Array,
      default: () => []
    },
    totalFact: {
      type: Number,
      default: 100
    }
  },
  computed: {
    ...mapGetters('sale/date', ['dateQuery', 'isPresentMonth']),
    directions () {
      const result = []

      this.directionsData.forEach((direction, key) => {
        result.push({
          background: direction.background,
          title: direction.title,
          plan: direction.plan,
          fact: direction.fact,
          predict: direction.predict,
          factPercent: direction.fact && direction.plan ? Math.floor(direction.fact / direction.plan * 100) : 0
        })
      })

      if (this.directionsTotalFactPercent < 100) {
        result.push({
          title: 'Остальные направления',
          fact: this.totalFact - this.directionsTotalFact
        })
      }

      return result
    },
    directionsBar () {
      const result = []
      const filteredDirections = this.directionsData.filter(el => el.fact > 0)

      if (this.directionsTotalFactPercent < 100) {
        filteredDirections.push({
          background: '#b9c3d8',
          title: 'Остальные направления',
          plan: this.totalFact - this.directionsTotalFact,
          fact: this.totalFact - this.directionsTotalFact
        })
      }

      filteredDirections.forEach((direction, key) => {
        result.push({
          start: key > 0 ? result[key - 1].stop : 0,
          stop: this.stopValue(direction, key, key > 0 ? result[key - 1] : undefined),
          background: direction.background,
          class: this.className(key, filteredDirections.length),
          title: direction.title,
          salesFact: this.totalFact, // sum of total sales
          plan: direction.plan,
          fact: direction.fact,
          salesFactPercent: this.percentOfTotalSales(direction.fact, this.totalFact), // percent of total sum sales
          factPercent: direction.fact && direction.plan ? Math.floor(direction.fact / direction.plan * 100) : 0,
          balloon: true
        })
      })

      return result
    },
    directionsTotalFact () {
      let result = 0

      this.directionsData.forEach(direction => {
        result += parseInt(direction.fact)
      })

      return result
    },
    directionsTotalFactPercent () { // percent of total sum sales
      let result = 0

      this.directionsData.forEach(direction => {
        result += this.percentOfTotalSales(direction.fact, this.totalFact)
      })

      return result
    }
  },
  methods: {
    stopValue (direction, key, prevDirection) {
      if (key === 0) {
        return direction.fact
      }

      return prevDirection.stop + direction.fact
    },
    className (key, length) {
      let className = ''

      if (length === 1) {
        return className
      }

      if (key === 0) {
        className = 'rounded-right-0'
      } else if (key === length - 1) {
        className = 'rounded-left-0'
      } else {
        className = 'rounded-0'
      }

      return className
    },
    restSum (plan, fact) {
      return plan && plan >= fact ? plan - fact : 0
    },
    percentOfTotalSales (directionFact, totalFact) {
      return directionFact && totalFact ? Math.floor(directionFact / totalFact * 100) : 0
    }
  }
}
</script>

<style>
.direction-progress .bar-progress__percent {
  display: none;
}
</style>
