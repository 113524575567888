<template>
  <div class="btn btn-secondary btn-remove">
    <span class="btn-remove__text">{{ text }}</span>
    <div
      class="btn-remove__icon"
      v-b-tooltip
      :title="tooltipText.length > 0 ? tooltipText : undefined"
      @click="$emit('click')"
    >
      <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.74895 1.46323C6.08368 1.1285 6.08368 0.585786 5.74895 0.251051C5.41421 -0.0836838 4.8715 -0.0836838 4.53677 0.251051L3 1.78782L1.46323 0.251051C1.1285 -0.0836838 0.585786 -0.0836838 0.251051 0.251051C-0.0836837 0.585786 -0.0836837 1.1285 0.251051 1.46323L1.78782 3L0.251051 4.53677C-0.0836838 4.8715 -0.0836838 5.41421 0.251051 5.74895C0.585786 6.08368 1.1285 6.08368 1.46323 5.74895L3 4.21218L4.53677 5.74895C4.8715 6.08368 5.41421 6.08368 5.74895 5.74895C6.08368 5.41421 6.08368 4.8715 5.74895 4.53677L4.21218 3L5.74895 1.46323Z" fill="#1F2641"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonRemove',
  props: {
    text: {
      type: String,
      default: 'Удалить'
    },
    tooltipText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.btn.btn-remove {
  cursor: default;
}
.btn-remove {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.btn-remove__text {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--title-color);
  white-space: nowrap;
}
.btn-remove__icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
