<template>
  <Block v-if="warningDeals.length">
    <div>Внимание! Обнаружены оплаты по сделкам у сотрудников, которым не назначен план. Добавьте сотрудников в план или перенесите сделки на других менеджеров.</div>
    <div class="warning-deals">
      <div
        v-for="(item, key) in warningDeals"
        :key="key"
        class="deals__item"
      >
        <div class="deals__info">
          <div class="deals__user">{{ item.name }}</div>
          <div class="deals__list">
            <div
              v-for="deal in item.deals"
              :key="deal.id"
              class="deal__item"
            >
              <template v-if="deal.source_link">
                <a :href="deal.source_link" target="_blank">{{ deal.title }} - <span class="currency font-weight-bold rx-title">{{ deal.sum | money }}</span></a>
              </template>
              <template v-else>
                <span>{{ deal.title }}</span> - <span class="currency font-weight-bold rx-title">{{ deal.sum | money }}</span>
              </template>
            </div>
          </div>
        </div>
        <div class="deals__action">
          <div class="btn btn-secondary" @click="addToPlan(item.user_id)">Добавить в план</div>
          <b-dropdown
            v-if="users.length"
            class="ml-10"
            right
            text="Изменить ответственного"
          >
            <b-dropdown-item
              v-for="user in users"
              :key="user.id"
              @click="changeResponsible(user.id, item.deals)"
            >
              {{ user.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </Block>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'WarningDeals',
  computed: {
    ...mapGetters('sale/date', ['dateQuery'])
  },
  data () {
    return {
      warningDeals: [],
      users: []
    }
  },
  async created () {
    await this.fetchDeals()
  },
  methods: {
    async fetchDeals () {
      try {
        const result = await this.$api.query(`sale/deal/warning${this.dateQuery}`)
        this.warningDeals = result

        if (this.warningDeals) {
          await this.fetchPlanUsers()
        }
      } catch (e) {
        this.error = 'Не удалось получить данные'
      }
    },
    async fetchPlanUsers () {
      try {
        const result = await this.$api.query('sale/user')
        this.users = result
      } catch (e) {
        this.error = 'Не удалось получить данные пользователей'
      }
    },
    async addToPlan (userId) {
      await this.$api.post(`sale/user-plan${this.dateQuery}`, {
        user_id: userId,
        min_sum: 0,
        sum: 0,
        status: 'empty',
        wish_sum: 0
      })

      this.$emit('plan-update')
    },
    async changeResponsible (userId, deals) {
      const dealsId = deals.map(el => { return parseInt(el.id) })

      await this.$api.post('plan/deal/update-responsible', {
        user_id: userId,
        deals_id: dealsId
      }).then(response => {
        if (response) {
          this.$emit('plan-update')
        }
      })
    }
  }
}
</script>

<style>
.warning-deals .deals__item {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
}
.warning-deals .deals__user {
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--title-color);
}
.warning-deals .deal__item {
  margin-bottom: 10px;
}
.warning-deals .deal__item:last-child {
  margin-bottom: 0;
}
.warning-deals .deals__action {
  min-width: 370px;
}
</style>
