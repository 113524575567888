<template>
  <Block
    class="alert-block"
    :grey="grey"
    v-if="!isHidden"
  >
    <Alert
      :icon="icon"
      :close="close"
      @closeAlert="closeAlert"
    >
      <slot></slot>
    </Alert>
  </Block>
</template>

<script>
import Alert from './Alert'

export default {
  name: 'AlertBlock',
  components: {
    Alert
  },
  data () {
    return {
      isHidden: false
    }
  },
  props: {
    icon: {
      type: String, // danger, warning, success
      default: 'danger'
    },
    close: {
      type: Boolean,
      default: false
    },
    grey: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeAlert () {
      this.isHidden = true
    }
  }
}
</script>
<style>
.alert-block {
  padding-bottom: 30px;
}
</style>
