<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else class="table-responsive mb-0 mt-30">
    <b-table-simple
      class="table_plan mb-0"
    >
      <b-thead>
        <b-tr>
          <b-th width="21%">Сотрудник</b-th>
          <b-th width="20%">Прогресс</b-th>
          <b-th colspan="3" class="text-center">Сделки</b-th>
          <b-th width="11%">Необходимо</b-th>
          <b-th width="12.5%">Выполнено</b-th>
          <b-th width="17%">Выставленные счета</b-th>
          <b-th></b-th>
        </b-tr>
        <b-tr>
          <b-th colspan="2"></b-th>
          <b-th class="text-center">В работе</b-th>
          <b-th class="text-center">Усп.</b-th>
          <b-th class="text-center">Пров.</b-th>
          <b-th colspan="4"></b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <template v-if="acceptedPlans && acceptedPlans.length > 0">
          <b-tr
            v-for="(item, index) in acceptedPlans"
            :key="item.id"
          >
            <b-td>
              <div class="user_info d-flex align-items-center">
                <RankLabel :place="index + 1" :empty="!item.fact_sum" />
                <router-link :to="{ name: 'sale/plan/personal', params: { id: item.user_id } }">
                  <Avatar :photo="users[item.user_id].avatar" :title="users[item.user_id].name" hover />
                </router-link>
              </div>
            </b-td>
            <b-td>
              <Bar
                style="max-width: 200px"
                :bars="[
                  {
                    start: 0,
                    stop: item.fact_sum + item.predict_sum,
                    class: 'text-right',
                    background: '#B9C3D8',
                    'z-index': 10,
                    title: 'Выставлено счетов на'
                  },
                  {
                    start: 0,
                    stop: item.fact_sum,
                    class: 'text-right',
                    background: 'multicolor',
                    'z-index': 20,
                    title: 'План выполнен на'
                  }
                ]"
                :max="parseInt(item.sum)"
                :pointerOffset="pointerOffset"
                :pointerCursor="isPresentMonth"
                small
              >
                <template slot="label-after">
                  <div class="bar-progress__label">
                    {{progressPercent(item.sum, item.fact_sum)}}%
                  </div>
                </template>
              </Bar>
            </b-td>
            <b-td class="text-center" width="7%">{{ item.deal_opened_count | money }}</b-td>
            <b-td class="text-center text-success" width="7%">{{ item.deal_success_count | money }}</b-td>
            <b-td class="text-center text-danger" width="7%">{{ item.deal_failed_count | money }}</b-td>
            <b-td>
              <span class="currency">{{ item.sum | money }}</span>
            </b-td>
            <b-td>
              <span class="currency">{{ item.fact_sum | money }}</span>
            </b-td>
            <b-td>
              <span class="currency">{{ item.predict_sum | money }}</span>
            </b-td>
            <b-td>
              <router-link :to="{ name: 'sale/plan/personal', params: { id: item.user_id } }" class="d-flex justify-content-end">
                <Arrow direction="right" tooltipText="Перейти на панель сотрудника" />
              </router-link>
            </b-td>
          </b-tr>
        </template>

        <template v-if="confirmPlans && confirmPlans.length > 0">
          <b-tr>
            <b-td class="table_subtitle" colspan="9">
              Не подтвердили план
            </b-td>
          </b-tr>
          <b-tr
            v-for="item in confirmPlans"
            :key="item.key"
          >
            <b-td>
              <div class="user_info d-flex align-items-center">
                <RankLabel empty question />
                <Avatar :photo="users[item.user_id].avatar" :title="users[item.user_id].name" />
              </div>
            </b-td>
            <b-td></b-td>
            <b-td class="text-center" width="7%">{{ item.deal_opened_count | money }}</b-td>
            <b-td class="text-center text-success" width="7%">{{ item.deal_success_count | money }}</b-td>
            <b-td class="text-center text-danger" width="7%">{{ item.deal_failed_count | money }}</b-td>
            <b-td>
              <span class="rx-text currency">{{ item.sum | money }}</span>
            </b-td>
            <b-td>
              <span class="rx-text currency">{{ item.fact_sum | money }}</span>
            </b-td>
            <b-td>
              <span class="currency">{{ item.predict_sum | money }}</span>
            </b-td>
            <b-td></b-td>
          </b-tr>
        </template>

        <template v-if="emptyPlans && emptyPlans.length > 0">
          <b-tr>
            <b-td class="table_subtitle" colspan="9">
              План не назначен
            </b-td>
          </b-tr>
          <b-tr
            v-for="item in emptyPlans"
            :key="item.key"
          >
            <b-td>
              <div class="user_info d-flex align-items-center">
                <RankLabel empty />
                <Avatar :photo="users[item.user_id].avatar" :title="users[item.user_id].name" />
              </div>
            </b-td>
            <b-td></b-td>
            <b-td class="text-center" width="7%">{{ item.deal_opened_count | money }}</b-td>
            <b-td class="text-center text-success" width="7%">{{ item.deal_success_count | money }}</b-td>
            <b-td class="text-center text-danger" width="7%">{{ item.deal_failed_count | money }}</b-td>
            <b-td>
              <span class="rx-text currency">{{ item.sum | money }}</span>
            </b-td>
            <b-td>
              <span class="rx-text currency">{{ item.fact_sum | money }}</span>
            </b-td>
            <b-td>
              <span class="currency">{{ item.predict_sum | money }}</span>
            </b-td>
            <b-td></b-td>
          </b-tr>
        </template>

        <template v-if="sendedPlans && sendedPlans.length > 0">
          <b-tr>
            <b-td class="table_subtitle" colspan="9">
              Хотят изменить план
            </b-td>
          </b-tr>
          <b-tr
            v-for="item in sendedPlans"
            :key="item.key"
          >
            <b-td>
              <div class="user_info d-flex align-items-center">
                <div class="user_rank">
                  <img :src="require(`@/assets/svg/warning_icon_lg.svg`)" />
                </div>
                <Avatar :photo="users[item.user_id].avatar" :title="users[item.user_id].name" />
              </div>
            </b-td>
            <b-td>
              <span class="rx-text">План - </span><span class="currency">{{ item.sum | money }}</span>
            </b-td>
            <b-td colspan="4">
              <span class="rx-text">Предложено сотрудником - </span><span class="currency">{{ item.wish_sum | money }}</span>
            </b-td>
            <b-td colspan="2">
              <div class="d-flex align-items-center">
                <span @click="accept(item.user_id)" role="button">
                  <SpriteIcon icon="success" />
                </span>
                <span class="ml-10" @click="decline(item.user_id)" role="button">
                  <SpriteIcon icon="danger" />
                </span>
              </div>
            </b-td>
            <b-td>
              <router-link :to="{ name: 'sale/plan/personal', params: { id: item.user_id } }" class="d-flex justify-content-end">
                <Arrow direction="right" tooltipText="Перейти на панель сотрудника" />
              </router-link>
            </b-td>
          </b-tr>
        </template>

        <template v-if="firedPlans && firedPlans.length > 0">
          <b-tr>
            <b-td class="table_subtitle" colspan="9">
              Уволенные сотрудники
            </b-td>
          </b-tr>
          <b-tr
            v-for="item in firedPlans"
            :key="item.id"
            class="opacity-50"
          >
            <b-td>
              <div class="user_info d-flex align-items-center">
                <RankLabel empty />
                <Avatar :photo="users[item.user_id].avatar" :title="users[item.user_id].name" />
              </div>
            </b-td>
            <b-td>
              <Bar
                style="max-width: 200px"
                :max="parseInt(item.sum)"
                :bars="[
                  {
                    start: 0,
                    stop: item.fact_sum + item.predict_sum,
                    class: 'text-right',
                    background: '#B9C3D8',
                    'z-index': 10,
                    title: 'Выставлено счетов на'
                  },
                  {
                    start: 0,
                    stop: item.fact_sum,
                    class: 'text-right',
                    background: 'var(--green-color)',
                    'z-index': 20,
                    title: 'План выполнен на'
                  }
                ]"
                :pointerOffset="pointerOffset"
                :pointerCursor="isPresentMonth"
                small
              >
                <template slot="label-after">
                  <div class="bar-progress__label">
                    {{progressPercent(item.sum, item.fact_sum)}}%
                  </div>
                </template>
              </Bar>
            </b-td>
            <b-td class="text-center" width="7%">{{ item.deal_opened_count | money }}</b-td>
            <b-td class="text-center text-success" width="7%">{{ item.deal_success_count | money }}</b-td>
            <b-td class="text-center text-danger" width="7%">{{ item.deal_failed_count | money }}</b-td>
            <b-td>
              <span class="currency">{{ item.sum | money }}</span>
            </b-td>
            <b-td>
              <span class="currency">{{ item.fact_sum | money }}</span>
            </b-td>
            <b-td>
              <span class="currency">{{ item.predict_sum | money }}</span>
            </b-td>
            <b-td></b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import Arrow from '@/components/ui/Arrow'
import Bar from '@/components/sale/Bar'
import moment from 'moment'
import SpriteIcon from '@/components/ui/icons/SpriteIcon'
import RankLabel from '@/components/sale/RankLabel'
import { mapGetters } from 'vuex'
import Avatar from '@/components/ui/Avatar'

export default {
  name: 'TableProgess',
  components: { Arrow, Bar, SpriteIcon, RankLabel, Avatar },
  data () {
    return {
      isLoading: true,
      emptyPlans: [],
      confirmPlans: [],
      sendedPlans: [],
      acceptedPlans: [],
      users: [],
      firedPlans: [],
      sum: 0,
      factSum: 0
    }
  },
  computed: {
    ...mapGetters('sale/date', ['dateQuery', 'isPresentMonth']),
    nonAcceptedEmpty () {
      return this.acceptedPlans.length
    },
    pointerOffset () {
      if (this.isPresentMonth) {
        const lastDay = moment().endOf('month').format('D') - 1
        const curDay = moment().format('D') - 1

        return Math.floor(curDay / lastDay * 100)
      } else {
        return 100
      }
    }
  },
  async created () {
    await this.fetchPlanData()
  },
  methods: {
    async fetchPlanData () {
      this.isLoading = true

      try {
        const result = await this.$api.query(`sale/user-plan${this.dateQuery}`)

        if (!result.plans || (result.plans && result.plans.length === 0)) {
          this.$router.push({
            name: 'sale/plan/progress/edit'
          })
        }

        await this.fetchUserData()
        this.emptyPlans = result.empty_plans
        this.confirmPlans = result.confirm_plans
        this.sendedPlans = result.sended_plans
        this.acceptedPlans = result.accepted_plans
        this.firedPlans = result.fired_plans
        this.plan = result.sum
        this.fact = result.fact_sum
      } catch (e) {
        this.error = 'Не удалось получить данные'
      }

      this.isLoading = false
    },
    async fetchUserData () {
      try {
        const result = await this.$api.query('user')
        if (result) {
          result.forEach(el => {
            this.users[el.id] = el
          })
        }
      } catch (e) {
        this.error = 'Не удалось получить данные пользователей'
      }
    },
    async accept (userId) {
      await this.$api.put('sale/user-plan/accept', {
        user_id: userId
      })

      this.$emit('plan-update')
    },
    async decline (userId) {
      await this.$api.put('sale/user-plan/decline', {
        user_id: userId
      })

      this.$emit('plan-update')
    },
    restSum (sum, factSum) {
      return sum && sum >= factSum ? sum - factSum : 0
    },
    progressPercent (plan, fact) {
      return plan && fact ? Math.floor(fact / plan * 100) : 0
    }
  }
}
</script>
