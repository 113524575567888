<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else class="table-responsive mb-0">
    <b-table-simple
      class="table_plan mb-0 mt-30"
    >
      <b-thead>
        <b-tr>
          <b-th>Отдел</b-th>
          <b-th>Прогресс</b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr v-for="(group, index) in groups" :key="group.id">
          <b-td width="21%">
            <div class="d-flex align-items-center">
              <RankLabel :place="index + 1" :empty="!group.fact_sum" />
              <div class="ml-15">{{ group.title }}</div>
            </div>
          </b-td>
          <b-td>
            <Bar
              :bars="[
                { start: 0, stop: group.fact_sum + group.predict_sum, background: '#B9C3D8', title: 'Выставлено счетов на' },
                { start: 0, stop: group.fact_sum, background: 'multicolor', title: 'План выполнен на' }
              ]"
              :max="group.sum"
              :pointerCursor="isPresentMonth"
              :pointerOffset="!isPresentMonth ? 100 : undefined"
              small
            >
              <template slot="label-after">
                <div class="bar-progress__label">
                  {{ group.percent }}%
                </div>
              </template>
            </Bar>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import Bar from '@/components/sale/Bar'
import RankLabel from '@/components/sale/RankLabel'
import { mapGetters } from 'vuex'

export default {
  name: 'TableGroupProgress',
  components: {
    Bar,
    RankLabel
  },
  data () {
    return {
      isLoading: true,
      groups: []
    }
  },
  computed: {
    ...mapGetters('sale/date', ['dateQuery', 'isPresentMonth'])
  },
  async created () {
    this.groups = await this.$api.query(`sale/user-plan/group${this.dateQuery}`)
    this.isLoading = false
  }
}
</script>
