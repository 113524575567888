<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else>
    <b-table-simple class="table_plan table_directions mb-0 mt-30">
      <b-thead>
        <b-tr>
          <b-th width="19%">Направление</b-th>
          <b-th width="35%"><span>План</span></b-th>
          <b-th width="12%"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td colspan="3" class="direction__info">
            <b-table-simple class="table_directions_edit">
              <b-tbody>
                <b-tr>
                  <b-td width="19%">
                    <div
                      class="direction__color"
                      :style="`background: ${newDirection.color};`"
                    >
                      <div
                        class="direction__color-wrapper"
                        @click="toggleColorPicker(-1)"
                      >
                        <SpriteIcon icon="color-picker" />
                      </div>
                      <ColorPicker
                        v-if="activeColorPickerIndex === -1"
                        :value="newDirection.color"
                        @input="updateDirectionColor(null, $event)"
                        v-on-clickaway="closeColorPicker"
                      />
                    </div>
                    <div class="direction__name">
                      <b-form-input
                        placeholder="Введите название направления"
                        v-model="newDirection.title"
                      />
                    </div>
                  </b-td>
                  <b-td width="35%">
                    <CurrencyInput
                      v-model="newDirection.sum"
                      placeholder="Введите сумму"
                    />
                  </b-td>
                  <b-td width="12%">
                    <div class="d-flex justify-content-end align-items-center">
                      <div class="font-weight-normal">
                        <a
                          href="#"
                          class="dotted-blue"
                          @click.prevent="addDirection()"
                        >
                          Добавить направление
                        </a>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td
                    colspan="3"
                    class="direction__form"
                  >
                    <div class="direction__products" v-if="showCatalog">
                      <CatalogDropdown
                        :catalog="catalog"
                        :activeSections="newDirection.sections"
                        :activeProducts="newDirection.products"

                        @sectionAdded="addDirectionSection(null, $event)"
                        @sectionRemoved="removeDirectionSection(null, $event, 'emit')"
                        @productAdded="addDirectionProduct(null, $event)"
                        @productRemoved="removeDirectionProduct(null, $event, 'emit')"
                      />
                      <ButtonRemove
                        v-for="sectionId in newDirection.sections"
                        :key="`section_${sectionId}`"
                        :text="sectionTitle(sectionId)"
                        tooltipText="Удалить раздел"
                        @click="removeDirectionSection(null, sectionId, 'click')"
                      />
                      <ButtonRemove
                        v-for="productId in newDirection.products"
                        :key="`product_${productId}`"
                        :text="productTitle(productId)"
                        tooltipText="Удалить товар"
                        @click="removeDirectionProduct(null, productId, 'click')"
                      />
                    </div>
                    <div class="direction__pipelines">
                      <b-dropdown variant="primary" text="Выбрать направления сделок">
                        <template v-if="pipelines && pipelines.length">
                          <b-dropdown-item
                            v-for="pipeline in pipelines"
                            :key="pipeline.id"
                            @click="addDirectionPipeline(null, pipeline.id)"
                          >
                            {{ pipeline.title }}
                          </b-dropdown-item>
                        </template>
                        <b-dropdown-item
                          v-else
                          disabled
                        >
                          Направления не найдены
                        </b-dropdown-item>
                      </b-dropdown>
                      <ButtonRemove
                        v-for="pipeline in newDirection.pipelines"
                        :key="pipeline"
                        :text="pipelines.find(el => el.id === pipeline).title"
                        tooltipText="Удалить направление"
                        @click="removeDirectionPipeline(null, pipeline)"
                      />
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-td>
        </b-tr>
        <b-tr
          v-for="(direction, directionIndex) in directions"
          :key="direction.id"
        >
          <b-td colspan="3" class="direction__info">
            <b-table-simple class="table_directions_edit">
              <b-tbody>
                <b-tr>
                  <b-td width="19%">
                    <div
                      class="direction__color"
                      :style="`background: ${direction.color};`"
                    >
                      <div
                        class="direction__color-wrapper"
                        @click="toggleColorPicker(directionIndex)"
                      >
                        <SpriteIcon icon="color-picker" />
                      </div>
                      <ColorPicker
                        v-if="directionIndex === activeColorPickerIndex"
                        :value="direction.color"
                        @input="updateDirectionColor(direction.id, $event)"
                        v-on-clickaway="closeColorPicker"
                      />
                    </div>
                    <div class="direction__name">
                      <b-form-input
                        placeholder="Введите название направления"
                        v-model="direction.title"
                        @blur="updateDirection(direction)"
                      />
                    </div>
                  </b-td>
                  <b-td width="35%">
                    <CurrencyInput
                      v-model="direction.sum"
                      @blur="updateDirection(direction)"
                      placeholder="Введите сумму"
                    />
                  </b-td>
                  <b-td width="12%">
                    <div class="d-flex justify-content-end align-items-center">
                      <div class="font-weight-normal">
                        <a
                          href="#"
                          class="dotted-blue"
                          @click.prevent="toggleDirection(directionIndex)"
                        >
                          {{ directionIndex !== activeDirectionIndex ? 'Редактировать' : 'Свернуть' }}
                        </a>
                      </div>
                      <div
                        class="ml-30"
                        @click.prevent="removeDirection(direction.id)"
                      >
                        <Remove tooltipText="Удалить направление" />
                      </div>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td
                    v-if="directionIndex === activeDirectionIndex"
                    colspan="3"
                    class="direction__form"
                  >
                    <div class="direction__products" v-if="showCatalog">
                      <CatalogDropdown
                        :catalog="catalog"
                        :activeSections="direction.sections"
                        :activeProducts="direction.products"

                        @sectionAdded="addDirectionSection(direction.id, $event)"
                        @sectionRemoved="removeDirectionSection(direction.id, $event, 'emit')"
                        @productAdded="addDirectionProduct(direction.id, $event)"
                        @productRemoved="removeDirectionProduct(direction.id, $event, 'emit')"
                      />
                      <ButtonRemove
                        v-for="sectionId in direction.sections"
                        :key="`section_${sectionId}`"
                        :text="sectionTitle(sectionId)"
                        tooltipText="Удалить раздел"
                        @click="removeDirectionSection(direction.id, sectionId, 'click')"
                      />
                      <ButtonRemove
                        v-for="productId in direction.products"
                        :key="`product_${productId}`"
                        :text="productTitle(productId)"
                        tooltipText="Удалить товар"
                        @click="removeDirectionProduct(direction.id, productId, 'click')"
                      />
                    </div>
                    <div class="direction__pipelines">
                      <b-dropdown variant="primary" text="Выбрать направления сделок">
                        <b-dropdown-item
                          v-for="pipeline in pipelines"
                          :key="pipeline.id"
                          @click="addDirectionPipeline(direction.id, pipeline.id)"
                        >
                          {{ pipeline.title }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <ButtonRemove
                        v-for="pipeline in direction.pipelines"
                        :key="pipeline"
                        :text="pipelines.find(el => el.id === pipeline).title"
                        tooltipText="Удалить направление"
                        @click="removeDirectionPipeline(direction.id, pipeline)"
                      />
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import Remove from '@/components/ui/icons/TrashIcon'
import CurrencyInput from '@/components/ui/CurrencyInput'
import ButtonRemove from '@/components/ui/ButtonRemove'
import { Twitter } from 'vue-color'
import { directive as onClickaway } from 'vue-clickaway'
import CatalogDropdown from '@/components/sale/CatalogDropdown/CatalogDropdown'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TableDirectionsEdit',
  components: {
    Remove,
    CurrencyInput,
    ButtonRemove,
    ColorPicker: Twitter,
    CatalogDropdown
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    directions: {
      type: Array
    },
    pipelines: {
      type: Array
    },
    catalog: {
      type: Array
    }
  },
  directives: {
    onClickaway: onClickaway
  },
  data () {
    return {
      activeDirectionIndex: null, // index of active color picker element
      activeColorPickerIndex: null, // index of active color picker element
      newDirection: {
        title: null,
        color: this.randomColor(),
        sum: null,
        sections: [],
        products: [],
        pipelines: []
      }
    }
  },
  computed: {
    ...mapGetters('sale/date', ['dateQuery']),
    ...mapState('sale/settings', ['deal_sum']),
    showCatalog () {
      return this.deal_sum !== 'field'
    }
  },
  async created () {
    await this.$store.dispatch('sale/settings/fetchSettings')

    this.fetchDirectionData()
  },
  methods: {
    fetchDirectionData () {
      this.$emit('data-update')
    },
    async updateDirection (direction) {
      try {
        await this.$api.put(`sale/direction/${direction.id}`, {
          title: direction.title,
          color: direction.color,
          sum: parseInt(direction.sum, 10),
          sections: direction.sections,
          products: direction.products,
          pipelines: direction.pipelines
        })
      } catch (e) {
        console.error('Не удалось обновить направление')
      }
    },
    async postDirectionPipeline (directionId, pipelineId) {
      try {
        await this.$api.post('sale/direction-pipeline', {
          direction_id: directionId,
          pipelines: pipelineId
        })
      } catch (e) {
        console.error('Не удалось добавить направление сделки')
      }
    },
    async deleteDirectionPipeline (directionId, pipelineId) {
      try {
        await this.$api.delete('sale/direction-pipeline', {
          direction_id: directionId,
          pipeline_id: pipelineId
        })
      } catch (e) {
        console.error('Не удалось удалить привяку направления')
      }
    },
    async postDirectionSection (directionId, sectionId) {
      try {
        await this.$api.post('sale/direction-section', {
          direction_id: directionId,
          sections: sectionId
        })
      } catch (e) {
        console.error('Не удалось добавить раздел направления')
      }
    },
    async deleteDirectionSection (directionId, sectionId) {
      try {
        await this.$api.delete('sale/direction-section', {
          direction_id: directionId,
          section_id: sectionId
        })
      } catch (e) {
        console.error('Не удалось удалить раздел направления')
      }
    },
    async postDirectionProduct (directionId, productId) {
      try {
        await this.$api.post('sale/direction-product', {
          direction_id: directionId,
          products: productId
        })
      } catch (e) {
        console.error('Не удалось добавить товар направления')
      }
    },
    async deleteDirectionProduct (directionId, productId) {
      try {
        await this.$api.delete('sale/direction-product', {
          direction_id: directionId,
          product_id: productId
        })
      } catch (e) {
        console.error('Не удалось удалить товар направления')
      }
    },
    async addDirection () {
      if (
        this.newDirection.title && this.newDirection.sum &&
        (this.newDirection.sections.length > 0 || this.newDirection.products.length > 0 || this.newDirection.pipelines.length > 0)
      ) {
        await this.$api.post(`sale/direction${this.dateQuery}`, this.newDirection).then(async response => {
          const direction = response

          if (direction.id) {
            const directionId = direction.id

            if (this.newDirection.pipelines.length) {
              await this.postDirectionPipeline(directionId, this.newDirection.pipelines)
            }
            if (this.showCatalog) {
              if (this.newDirection.sections.length) {
                await this.postDirectionSection(directionId, this.newDirection.sections)
              }
              if (this.newDirection.products.length) {
                await this.postDirectionProduct(directionId, this.newDirection.products)
              }
            }
          }
        })

        this.newDirection = {
          title: null,
          color: this.randomColor(),
          sum: null,
          sections: [],
          products: [],
          pipelines: []
        }

        this.fetchDirectionData()
      }
    },
    async removeDirection (directionId) {
      const directionIndex = this.directions.findIndex(el => el.id === directionId)
      if (directionIndex > -1) {
        this.directions.splice(directionIndex, 1)
      }

      await this.deleteDirectionPipeline(directionId, null)
      await this.deleteDirectionSection(directionId, null)
      await this.deleteDirectionProduct(directionId, null)

      try {
        await this.$api.delete(`sale/direction/${directionId}`)
      } catch (e) {
        console.error('Не удалось удалить направление')
      }
    },
    toggleDirection (directionIndex) {
      if (this.activeDirectionIndex === directionIndex) {
        this.closeDirection()
      } else {
        this.activeDirectionIndex = directionIndex
      }
    },
    closeDirection () {
      this.activeDirectionIndex = null
    },
    toggleColorPicker (directionIndex = undefined) {
      if (this.activeColorPickerIndex === directionIndex || directionIndex === undefined) {
        this.closeColorPicker()
      } else {
        this.activeColorPickerIndex = directionIndex
      }
    },
    closeColorPicker () {
      this.activeColorPickerIndex = null
    },
    addDirectionPipeline (directionId, pipelineId) {
      const direction = this.getDirectionById(directionId)
      if (direction.pipelines.findIndex(el => el === pipelineId) !== -1) {
        return
      }
      direction.pipelines.push(parseInt(pipelineId))

      if (directionId !== null) {
        this.postDirectionPipeline(directionId, pipelineId)
      }
    },
    removeDirectionPipeline (directionId, pipelineId) {
      const direction = this.getDirectionById(directionId)
      const pipelineIndex = direction.pipelines.findIndex(el => el === pipelineId)

      if (pipelineIndex > -1) {
        direction.pipelines.splice(pipelineIndex, 1)
      }

      if (directionId !== null) {
        this.deleteDirectionPipeline(directionId, pipelineId)
      }
    },
    addDirectionSection (directionId, sectionId) {
      const direction = this.getDirectionById(directionId)
      if (direction.sections.findIndex(el => el === parseInt(sectionId)) !== -1) {
        return
      }

      direction.sections.push(parseInt(sectionId))

      if (directionId !== null) {
        this.postDirectionSection(directionId, sectionId)
      }
    },
    removeDirectionSection (directionId, sectionId, event = 'emit') {
      const direction = this.getDirectionById(directionId)
      const sectionIndex = direction.sections.findIndex(el => el === parseInt(sectionId))

      if (event === 'click') { // if section deactivated from button click event, then manually deactivate products and sections
        const section = this.findNested(this.catalog, sectionId, 'section')
        const parentSection = this.findNested(this.catalog, section.id, 'section')
        const activeProducts = section.children.filter(el => el.type === 'product' && direction.products.indexOf(parseInt(el.id)) !== -1)
        const activeSections = section.children.filter(el => el.type === 'section' && direction.sections.indexOf(parseInt(el.id)) !== -1)

        if (activeSections.length === 0 && activeProducts.length === 0) {
          this.removeDirectionSection(directionId, parentSection.id, 'click')
        }
        if (activeProducts.length > 0) {
          activeProducts.forEach(product => {
            this.removeDirectionProduct(directionId, product.id, 'emit')
          })
        }
        if (activeSections.length > 0) {
          activeSections.forEach(section => {
            this.removeDirectionSection(directionId, section.id, 'click')
          })
        }
      }

      if (sectionIndex > -1) {
        direction.sections.splice(sectionIndex, 1)
      }

      if (directionId !== null) {
        this.deleteDirectionSection(directionId, sectionId)
      }
    },
    addDirectionProduct (directionId, productId) {
      const direction = this.getDirectionById(directionId)
      if (direction.products.findIndex(el => el === parseInt(productId)) !== -1) {
        return
      }

      direction.products.push(parseInt(productId))

      if (directionId !== null) {
        this.postDirectionProduct(directionId, productId)
      }
    },
    removeDirectionProduct (directionId, productId, event = 'emit') {
      const direction = this.getDirectionById(directionId)
      const productIndex = direction.products.findIndex(el => parseInt(el) === parseInt(productId))

      if (productIndex > -1) {
        direction.products.splice(productIndex, 1)
      }

      if (event === 'click') { // if product deactivated from button click event, then manually deactivate parent section
        const product = this.findNested(this.catalog, productId, 'product')
        const productSection = this.findNested(this.catalog, product.section_id, 'section')
        const activeProducts = productSection.children.filter(el => el.type === 'product' && direction.products.indexOf(parseInt(el.id)) !== -1)
        const activeSections = productSection.children.filter(el => el.type === 'section' && direction.section.indexOf(parseInt(el.id)) !== -1)
        if (activeProducts.length === 0 && activeSections.length === 0) {
          this.removeDirectionSection(directionId, productSection.id, 'click')
        }
      }

      if (directionId !== null) {
        this.deleteDirectionProduct(directionId, productId)
      }
    },
    updateDirectionColor (directionId, value) {
      let direction = this.newDirection

      if (directionId !== null) {
        direction = this.directions.find(el => el.id === directionId)
      }

      direction.color = value.hex

      this.closeColorPicker()

      if (directionId !== null) {
        this.updateDirection(direction)
      }
    },
    sectionTitle (sectionId) {
      const finded = this.findNested(this.catalog, sectionId, 'section')
      return finded ? finded.title : 'Раздел без названия'
    },
    productTitle (productId) {
      const finded = this.findNested(this.catalog, productId, 'product')
      return finded ? finded.title : 'Товар без названия'
    },
    findNested (array, id, type) {
      let foundObj
      JSON.stringify(array, (_, nestedValue) => {
        if (nestedValue && (nestedValue.id === id || parseInt(nestedValue.id) === id) && nestedValue.type === type) {
          foundObj = nestedValue
        }
        return nestedValue
      })
      return foundObj
    },
    randomColor () {
      const colors = ['#ff6900', '#fcb900', '#7bdcb5', '#00d084', '#8ed1fc', '#0693e3', '#abb8c3', '#eb144c', '#f78da7', '#9900ef']
      return colors[Math.floor(Math.random() * colors.length)]
    },
    getDirectionById (directionId) {
      return directionId !== null ? this.directions.find(el => el.id === directionId) : this.newDirection
    }
  }
}
</script>

<style>
.direction__color {
  width: 40px;
  height: 40px;
  border-radius: 7px;
  position: relative;
  cursor: pointer;
}
.direction__color .direction__color-wrapper {
  width: 100%;
  height: 100%;
}
.direction__color .sprite_icon.color-picker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.direction__color .vc-twitter.vc-twitter-top-left-triangle {
  position: absolute;
  top: calc(100% + 11px);
  z-index: 10;
}
.direction__name {
  margin-left: 5px;
}
.direction__color,
.direction__name {
  display: inline-block;
  vertical-align: middle;
}
.table_directions .form-control {
  max-width: 190px;
}
.direction__products {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.direction__pipelines {
  display: flex;
  flex-wrap: wrap;
}

.b-table.table_directions .b-table.table_directions_edit tr:first-child td {
  padding-top: 0;
  padding-bottom: 0;
}

.b-table.table_directions_edit {
  margin-bottom: 0;
}
.b-table.table_directions .b-table.table_directions_edit td {
  border-bottom: none;
}
.b-table.table_plan tbody td.direction__form {
  padding-top: 30px;
}
.btn-remove {
  margin-bottom: 10px;
}
.b-dropdown.btn-group {
  display: inline-block;
}
</style>
